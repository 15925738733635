<template>
  <v-app>
    <Sidebar/>
   <v-container class="alltheitens">
     <v-row class="box-input justify-center mx-auto text-center">
        <v-select
        :items="eventos" item-text="name" item-value="_id"
        attach v-model="selectEvent" @change="GetTickets()"
        dark outlined
        label="Evento"                              
        ></v-select>
     </v-row>
      <!-- Cards de ingressos-->
      <v-row justify="center" align="center" >
        <!--Ingressos vendidos-->
        <v-col justify="center" align="center">
          <v-card  shaped class="cards-padrao c-azul">
            <v-card-title class="text-center d-block">
             <p>Ingressos vendidos</p>
             <h2 v-if="evento.qnd_vendida != null"> {{evento.qnd_vendida}}/{{ evento.qnd_total}}</h2>
             <h2 v-else>0/0</h2>
            </v-card-title>
            <div class="m-5"> 
              <div id="chart" class="m-10">
                <apexchart type="radialBar" height="350"
                :options="chartOptions" :series="[porcentagem_vendida]"></apexchart>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col justify="center" align="center" class="mx-auto">
          <v-card  shaped class="cards-padrao c-amarelo">
            <v-card-title class="text-center d-block">
             <p>Ingressos Lidos</p>
              <h2 v-if="evento.qnd_lida != null"> {{evento.qnd_lida}}/{{ evento.qnd_total}}</h2>
              <h2 v-else>0/0</h2>
            </v-card-title>
            <div class="m-5"> 
              <div id="chart" class="m-10">
                <apexchart type="radialBar" height="350"
                :options="chartOptions2" :series="[porcentagem_lida]"></apexchart>
              </div>
            </div>
          </v-card>
        </v-col>
         <v-col justify="center" align="center">
          <v-card  shaped class="cards-padrao c-vermelho">
            <v-card-title class="text-center d-block">
             <p>Ingressos Pendentes</p>
              <h2 v-if="evento.qnd_pendente != null"> {{evento.qnd_pendente}}/{{ evento.qnd_total}}</h2>
              <h2 v-else>0/0</h2>
            </v-card-title>
            <div class="m-5"> 
              <div id="chart" class="m-10">
                <apexchart type="radialBar" height="350"
                :options="chartOptions3" :series="[porcentagem_pendente]"></apexchart>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
        <v-card-actions class="gerar-cortesia">
            <v-btn
            @click.stop="criacortesia=true">
              <v-icon
              icon:mdi-view-dashboard
              color="blue"
              large
              >mdi-plus</v-icon> Gerar ingresso cortesia
            </v-btn>
        </v-card-actions>
        <Criarcortesia :visible="criacortesia" @close="criacortesia=false"/>
    </v-container>
  </v-app>
</template>

<script>
import Criarcortesia from '@/components/Bilheteria/Criacortesia'
import Sidebar from '@/components/Sidebar/Sidebar'
import { app, http } from '@/server'
import {showError} from '@/global'
import { replaceUrl } from '@/functions/helpers'

export default {
  name: 'Bilheteria',
  components: {
    Criarcortesia,
    Sidebar
  },
  data(){
    return{
      criacortesia: false,
      selectEvent: null,
      data: new Date(),
      eventos: [],
      dialog: false,
      e6:1,
      close: false,
      evento:{
      qnd_total: null,
      qnd_vendida: null,
      qnd_pendente: null,
      qnd_lida: null,
      },
      porcentagem_pendente: 0,
      porcentagem_lida: 0,
      porcentagem_vendida: 0,
      chartOptions: {
         chart: {
         height: 250,
         type: 'radialBar',
         },
         plotOptions: {
            radialBar: {
            hollow: {
               size: '45%',
             }
           },
          },
       labels: ['VENDIDOS'],
      }, 
     chartOptions2: {
       chart: {
         height: 250,
         type: 'radialBar',
        },
       colors:['#eead2d'],
       plotOptions: {
            radialBar: {
            hollow: {
               size: '45%',
               //background: '#c6c6c6',
             }
            },
           
        },
       labels: ['LIDOS'],
      },   
      chartOptions3: {
       chart: {
         height: 250,
         type: 'radialBar',
        },
       colors:['#ff0000'],
       plotOptions: {
            radialBar: {
            hollow: {
               size: '45%',
               //background: '#c6c6c6',
             }
            },
           
        },
       labels: ['PENDENTES'],
      },  
    }
  },
  mounted() {
    document.title = 'Bilheteria'
    this.GetEventos()
  },
  methods:{
    GetEventos() {
      http.request(app.getEventos)
      .then(res => {
        this.eventos = res.data
      })
      .catch((err)=>{
        showError()
      })
    },
    GetTickets() {
      const url = app.getTickets.url
      app.getTickets.url = replaceUrl(url, '{event_id}', this.selectEvent)
      http.request(app.getTickets)
      .then(res => {
        app.getTickets.url = '/promoter/selltickets/{event_id}'
        console.log(res.data)
        this.zeroAgain()
        this.evento.qnd_total = res.data.total
        this.evento.qnd_vendida = res.data.pay
        this.evento.qnd_pendente = res.data.pending
        this.evento.qnd_lida = res.data.read
        console.log(this.evento)
        this.calcPorcentagem()
      })
      .catch((err)=>{
        showError()
        console.log(err)

      })
    },
    calcPorcentagem() {
      const total = this.evento.qnd_total
      const vendidos =this.evento.qnd_vendida
      const lidos = this.evento.qnd_lida
      const pendente = this.evento.qnd_pendente
      if( vendidos >= 1 && total != null){
        const porcentVendida = `${(vendidos / total) * 100}`
        this.porcentagem_vendida = Math.round(porcentVendida)
      }
      if( lidos >= 1 && total != null){
        const porcentLido = `${(lidos / total) * 100}`
        this.porcentagem_lida = Math.round(porcentLido)
      }
      if(pendente >= 1 && total != null){
      const porncetPendente = `${(pendente / total) * 100}`
      this.porcentagem_pendente = Math.round(porncetPendente)
      }
    
    },
    zeroAgain(){
      this.porcentagem_pendente = 0
      this.porcentagem_vendida = 0
      this.porcentagem_lida = 0
    }
  },

}
</script>
<style scoped>

.cards-padrao{
  text-align: center;
  width: 260px;
  height: 450px;
  padding: 10px;
  margin:5px;
}
@media (max-width: 600px){
.box-input{
margin-top: 10px !important;
}
}
@media (max-width: 1200px){
  .box-input{
    margin-top: 10px !important;
  }
}
.c-azul{
  border-right: solid 10px #0a8ed5 !important;
  border-bottom: solid 10px #0a8ed5 !important;
}
.c-amarelo{
  border-right: solid 10px #eead2d!important;
  border-bottom: solid 10px #eead2d !important;
}
.c-vermelho{
    border-bottom: solid 5px red !important;
    border-right: solid 5px red !important;
}
.botao-adicionar{
  margin-top:270px;
  background: #c6c6c6;
}
@media (min-width: 1264px) {
  .alltheitens{
  margin-left: 250px !important;
}
}
.box-input{
  width: 450px;
  color:#fff;
  margin-bottom: -30px;
  margin-top: -60px;
}
.gerar-cortesia{
  bottom: 0;
  position:fixed;        
  width: 90%;
  text-align: center;
  z-index: 1;
}

</style>