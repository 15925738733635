<template>
<v-dialog v-model="show" max-width="500px">
  <v-card>
    <v-stepper
     v-model="e2"
     vertical
     dark
     >
       <v-stepper-step
       :complete="e2 > 1"
       step="1"
       >
       Criar cortesia
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-form
          ref="form"
          lazy-validation
          v-model="valid"
          class="justify-center mx-auto text-center">
            <v-card
            class="mb-5"
            height="350px"
            >
            <v-row >
                <v-col justify="center" align="center" class="mx-auto mt-5">
                  <h4> Quantidade de cortesias: </h4>
                </v-col>
            </v-row>
            <hr>
            <v-row  >
                <v-col justify="center" align="center" class="mx-auto">
                  <v-text-field class="uso-mascara" type="number" 
                  v-model="query"
                  label="Quantidade"></v-text-field>
                  <h4 class="uso-mascara">Data criação: {{date}}</h4>
                  <v-select class="mt-5" style="max-width: 80%;"
                  :items="eventos" item-text="name" item-value="_id"
                  attach v-model="selectEvent"
                  dark outlined
                  label="Evento"                              
                  ></v-select>
                </v-col>
            </v-row>
            </v-card>
          </v-form>
         <v-btn
          color="primary"
          @click="postCortesia()"
          :disabled="!valid"
          :loading="loading2" >
          Salvar
          </v-btn>
          <v-btn text
          @click="$emit('close')">
           Cancel
          </v-btn>
          </v-stepper-content>
     </v-stepper>
  </v-card>
</v-dialog>
</template>

<script>
import {showError, showSucess} from '@/global'

import { app, http } from '@/server'
import { replaceUrl } from '@/functions/helpers'

// import axios from "axios"
export default {
  props: ['visible'],
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
      },

    }
  },
  data(){
    return{
      close: false,
      e2:1,
      date:'',
      eventos: null,
      loading2: false,
      valid:true,
      loader: null,
      tickets: {},
      selectEvent: null,
      cortesia:{
        localId: null,
	      batchId:  null,
        ticketId: null,
      },
      query: null,
    }
  },
   created() {
     //this.getTickets();
  },
   methods: {
    GetEventos() {
      http.request(app.getEventos)
      .then(res => {
        this.eventos = res.data
        this.GetBares()

      })
      .catch((err)=>{
        showError()
      })
    },
    printDate: function () {
    return new Date().toLocaleDateString();
            
    },
    postCortesia(){
      this.cortesia.localId = this.selectEvent
      this.cortesia.batchId = this.selectEvent
      this.cortesia.ticketId = this.selectEvent

        console.log(this.cortesia)
        const url = app.createCortesia.url
        app.createCortesia.data = this.cortesia
        app.createCortesia.url = replaceUrl(url, '{query}', this.query)

        http.request(app.createCortesia)
        .then(res =>{
            if(res.status === 200){
              showSucess(res)
            }
        })
        .catch((err)=>{
          if (err != 200){    
            showError(err)
          }
        })
    },

  },
  watch: {
     loader () {
        const l = this.loader
        this[l] = !this[l]
        setTimeout(() => (this[l] = false), 3000)
        this.loader = null
      },
  },
  mounted() {
    this.GetEventos()
    this.date = this.printDate();
  },
}
</script>   
<style scoped>
.uso-mascara {
  height: 20px;
  width: 50%;
  margin-top: 50px;
}
.ticket{
  color: #000;
}
</style>